import React from "react";
import classnames from 'classnames';
import styled from "styled-components";

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const FullPageCenter = styled(Center)`
  position: absolute;
  min-height: 100vh;
`;

export const Loader = ({ white = false }) => {
  return (
    <Center>
      <div className={classnames("spinner-border", white ? "text-white" : "text-secondary")} role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </Center>
  );
};

const FullScreenLoader = () => {
  return (
    <FullPageCenter>
      <Loader />
    </FullPageCenter>
  );
};

export default FullScreenLoader