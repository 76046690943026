export const FETCH_ICE_SERVERS = "FETCH_ICE_SERVERS";
export const ICE_SERVERS_SUCCESS = "ICE_SERVERS_SUCCESS";
export const ON_CHANGE_ROOM = "rooms/onChange";
export const ON_CHANGE_ROOM_SUCCESS = "rooms/onChangeSuccess";

export const fetchIceServers = () => ({
  type: FETCH_ICE_SERVERS,
});

export const iceServersSuccess = (payload) => ({
  type: ICE_SERVERS_SUCCESS,
  payload,
});

export const onChangeRoom = (payload) => ({
  type: ON_CHANGE_ROOM,
  payload,
});

export const onChangeRoomSuccess = (payload) => ({
  type: ON_CHANGE_ROOM_SUCCESS,
  payload,
});

