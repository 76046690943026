import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";

import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore"; // <- needed if using firestore
import {
  webrtcReducer,
  rooms,
  peers,
  userPanel,
  controls,
} from "./redux/reducers"; // TODO: cleanup example code and rename reducer

import createSagaMiddleware from "redux-saga";
import p2p from "./redux/sagas/p2p";

import createSagaMonitor from "@clarketm/saga-monitor";

// configuration
const config = {
  level: "log",
  effectTrigger: true,
  effectResolve: true,
  actionDispatch: true,
};

let sagaMiddleware = createSagaMiddleware();

if (process.env.NODE_ENV === "developmnt") {
  sagaMiddleware = createSagaMiddleware({
    sagaMonitor: createSagaMonitor(config),
  });
}

const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer, // <- needed if using firestore
  webrtc: webrtcReducer,
  rooms: rooms.reducer,
  peers: peers.reducer,
  userPanel: userPanel.reducer,
  controls: controls.reducer,
});
// Create store with reducers and initial state

export const store = configureStore({
  reducer: rootReducer,
  middleware: [sagaMiddleware],
});

/* hmr */
let sagaRunner;
if (process.env.NODE_ENV === "development" && module.hot) {
  store.replaceReducer(rootReducer);
  if (sagaRunner) {
    sagaRunner.cancel();
  }
}
/* hmr */

sagaRunner = sagaMiddleware.run(p2p);
