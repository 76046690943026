import { useFirestoreConnect, useFirebase } from "react-redux-firebase";
import { useSelector } from "react-redux";

const RoomNotifications = ({ roomId }) => {
  const query = {
    collection: "roomNotifications",
    subcollections: [
      {
        collection: "messages",
        orderBy: ["createdAt", "desc"],
        limit: 20,
      },
    ],
    doc: roomId,
    storeAs: "roomNotifications",
  };
  useFirestoreConnect(query);
  return null;
};

const UserNotifications = ({ userId }) => {
  useFirestoreConnect([
    {
      collection: "notifications",
      subcollections: [
        {
          collection: "messages",
          orderBy: ["createdAt", "desc"],
          limit: 20,
        },
      ],
      doc: userId,
      storeAs: "notifications",
    },
  ]);
  return null;
};

export const Notifications = () => {
  const firebase = useFirebase();
  const userId = firebase.auth().currentUser.uid;
  const roomId = useSelector((state) => state?.webrtc?.currentRoomId);
  const teamId = useSelector(
    (state) => state.firebase?.profile?.token?.claims?.team
  );
  const isOfficeMember = !!teamId;

  if (!isOfficeMember) return null;

  return (
    <>
      {roomId ? <RoomNotifications roomId={roomId} /> : null}
      {userId ? <UserNotifications userId={userId} /> : null}
    </>
  );
};
