import { ON_CHANGE_ROOM, ON_CHANGE_ROOM_SUCCESS } from "./actions";
import { createSlice } from "@reduxjs/toolkit";

const onLogout = "@@reactReduxFirebase/LOGOUT";

// <rooms>
export const rooms = createSlice({
  name: "rooms",
  initialState: {},
  reducers: {
    onLobby() {},
  },
});

export const { onLobby } = rooms.actions;
// </rooms>

export const userPanel = createSlice({
  name: "userPanel",
  initialState: {
    isOpen: false,
    userId: undefined,
  },
  reducers: {
    onShowUser(state, action) {
      state.isOpen = true;
      state.userId = action.payload.userId;
      return state;
    },
    onClose() {
      return { isOpen: false, userId: undefined };
    },
  },
  extraReducers: {
    [onLogout]: () => {
      return { isOpen: false, userId: undefined };
    },
  },
});

export const { onShowUser, onClose } = userPanel.actions;

// TODO: move all display/ reducers here
export const controls = createSlice({
  name: "controls",
  initialState: {
    showMeet: false,
  },
  reducers: {
    onShowMeet(state) {
      state.showMeet = true;
    },
    onShowMap(state) {
      state.showMeet = false;
    },
  },
  extraReducers: {
    [onLogout]: (state, action) => {
      return {};
    },
  },
});

export const { onShowMeet, onShowMap } = controls.actions;

export const peers = createSlice({
  name: "peers",
  initialState: {},
  reducers: {
    onScreenShare(state, action) {
      return state;
    },
    onAddAudioTrack(state, action) {
      const { peerId, track } = action.payload;
      // add peer if doesn't exist
      if (!(peerId in state)) {
        state[peerId] = {};
      }

      const peer = state[peerId];
      peer.audioTracks = [track];
      return state;
    },
    onRemoveAudioTrack(state, action) {
      const { peerId } = action.payload;
      // this only work if we always receive
      // a single audio track
      delete state[peerId]?.audioTracks;
      return state;
    },
    onAddVideoTrack(state, action) {
      const { peerId, track } = action.payload;
      // add peer if doesn't exist
      if (!(peerId in state)) {
        state[peerId] = {};
      }
      const peer = state[peerId];
      const existingTracks = peer.videoTracks || [];
      peer.videoTracks = [...existingTracks, track];
      return state;
    },
    onRemoveVideoTrack(state, action) {
      const { peerId, id } = action.payload;
      const peer = state[peerId];
      const filteredTracks = peer.videoTracks.filter(
        (track) => track.id !== id
      );
      peer.videoTracks = filteredTracks;
      return state;
    },
    onPeerData(state, action) {
      const { peerId, userId, peer } = action.payload;

      const oldPeerId = state[peerId];
      if (oldPeerId) {
        delete state[oldPeerId];
      }

      if (!(peerId in state)) {
        state[peerId] = {};
      }
      state[peerId].userId = userId;
      state[peerId].peer = peer;
      return state;
    },
    onPeerClose(state, action) {
      const { peerId } = action.payload;
      delete state[peerId];
      return state;
    },
  },
  extraReducers: {
    [ON_CHANGE_ROOM]: (state, action) => {
      return {};
    },
    [onLobby().type]: () => {
      return {};
    },
    [onLogout]: (state, action) => {
      return {};
    },
  },
});
export const {
  onPeerClose,
  onPeerData,
  onAddAudioTrack,
  onAddVideoTrack,
  onRemoveAudioTrack,
  onRemoveVideoTrack,
} = peers.actions;

const defaultState = {
  currentRoomId: undefined,
  currentRoomName: undefined,
  isRoomReady: true,
  isInitialized: false,
  hasMicOn: false,
  hasCamOn: false,
  hasCaptureOn: false,
  localScreenShare: new MediaStream(),
  teamId: undefined,
  teamName: undefined,
  rooms: {},
  isLoadingControls: false,
  localStream: new MediaStream(),
  showSettings: false,
  isReady: false,

  // used to control accessible features in a room
  displayChatToggle: false,
  displayMicToggle: false,
  displayCamToggle: false,
  displayCaptureToggle: false,
  showMessages: false,
};

const getRoomFeatures = (name) => {
  switch (true) {
    case /open-space/.test(name):
      return {
        displayMicToggle: false,
        displayCamToggle: false,
        displayCaptureToggle: false,
        showMessages: true,
      };
    case /conference/.test(name):
      return {
        displayMicToggle: true,
        displayCamToggle: true,
        displayCaptureToggle: true,
        showMessages: true,
      };
    case /1on1/.test(name):
      return {
        displayMicToggle: true,
        displayCamToggle: true,
        displayCaptureToggle: true,
        showMessages: true,
      };
    case /kitchen/.test(name):
      return {
        displayMicToggle: true,
        displayCamToggle: true,
        displayCaptureToggle: false,
        showMessages: true,
      };
    case /lounge/.test(name):
      return {
        displayMicToggle: true,
        displayCamToggle: true,
        displayCaptureToggle: false,
        showMessages: true,
      };
    case /zen/.test(name):
      return {
        displayMicToggle: false,
        displayCamToggle: false,
        displayCaptureToggle: false,
        showMessages: false,
      };
    default:
      return {
        displayMicToggle: true,
        displayCamToggle: true,
        displayCaptureToggle: true,
        showMessages: true,
      };
  }
};

export const webrtcReducer = (state = defaultState, action) => {
  switch (action.type) {
    case onLobby().type:
      return {
        ...defaultState,
      };
    case "ICE_SERVERS_SUCCESS":
      return {
        ...state,
        iceServers: action.payload,
      };
    case ON_CHANGE_ROOM:
      return {
        ...state,
        isRoomReady: false,
        hasCaptureOn: false,
        isInitialized: true,
        currentRoomId: action.payload.roomId,
        currentRoomName: action.payload.roomName,
      };
    case ON_CHANGE_ROOM_SUCCESS:
      const roomId = action.payload.roomId;
      const roomFeatures = getRoomFeatures(roomId);
      return {
        ...state,
        currentRoomId: roomId,
        isRoomReady: true,
        isReady: true,
        currentRoomName: action.payload.roomName,
        ...roomFeatures,
      };

    case "local/onCameraOn":
      return {
        ...state,
        isLoadingControls: true,
      };
    // used to block ui until action has completed
    case "local/onMicOnSuccess":
      return {
        ...state,
        hasMicOn: true,
        isLoadingControls: false,
      };
    case "local/onCameraOnSuccess":
      return {
        ...state,
        hasCamOn: true,
        isLoadingControls: false,
      };
    case "local/onCameraOnFailure":
      return {
        ...state,
        hasCamOn: false,
        isLoadingControls: false,
      };
    case "local/onCaptureOnSuccess":
      return {
        ...state,
        hasCaptureOn: true,
        isLoadingControls: false,
      };

    case "local/onCaptureOffSuccess":
      return {
        ...state,
        hasCaptureOn: false,
        isLoadingControls: false,
      };

    case "local/onCaptureOnFailure":
      return {
        ...state,
        isLoadingControls: false,
      };
    case "local/onCaptureOffFailure":
      return {
        ...state,
        isLoadingControls: false,
      };
    case "local/onShowMessages":
      return {
        ...state,
        showMessages: action.payload.showMessages,
      };

    case "local/onMicOffSuccess":
      return {
        ...state,
        hasMicOn: false,
        isLoadingControls: false,
      };

    case "local/onCameraOffSuccess":
      return {
        ...state,
        hasCamOn: false,
        isLoadingControls: false,
      };
    case "display/onHideSettings":
      return {
        ...state,
        showSettings: false,
      };
    case "display/onShowSettings":
      return {
        ...state,
        showSettings: true,
      };
    case onLogout:
      return {
        ...defaultState,
      };
    default:
      return state;
  }
};
