import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore"; // <- needed if using firestore
import "firebase/database"; // <- needed if using firestore
import "firebase/analytics";
import "firebase/storage";
import "firebase/functions";
import "firebase/performance";
import "firebase/messaging";

import { store } from "./store";

import { combineReducers } from "redux";
import { firebaseReducer } from "react-redux-firebase";
import { createFirestoreInstance, firestoreReducer } from "redux-firestore"; // <- needed if using firestore

const testConfig = {
  apiKey: "AIzaSyDaHKdURY7qBJVVIbjeiNXSB2RMQEaz9t8",
  authDomain: "knockapphq.firebaseapp.com",
  databaseURL: "https://knockapphq.firebaseio.com",
  projectId: "knockapphq",
  storageBucket: "knockapphq.appspot.com",
  messagingSenderId: "698044996326",
  appId: "1:698044996326:web:8e1b631ff6fe7b81a3f927",
  measurementId: "G-YD9YJ6EZV6",
};

var productionConfig = {
  apiKey: "AIzaSyDaHKdURY7qBJVVIbjeiNXSB2RMQEaz9t8",
  authDomain: "knockapphq.firebaseapp.com",
  databaseURL: "https://knockapphq.firebaseio.com",
  projectId: "knockapphq",
  storageBucket: "knockapphq.appspot.com",
  messagingSenderId: "698044996326",
  appId: "1:698044996326:web:920ed63e1a9bbf10a3f927",
  measurementId: "G-C4FN81YBR1",
};
const firebaseUseTestApp =
  process.env.REACT_APP_FIREBASE_USE_TEST_APP === "true";
const config = firebaseUseTestApp ? testConfig : productionConfig;
console.log("Using production metrics", !firebaseUseTestApp);

// react-redux-firebase config
const rrfConfig = {
  userProfile: "profiles",
  enableClaims: true,
  useFirestoreForProfile: true, // use Firestore for profile instead of RTDB
  presence: "presence",
};

const firebaseUseLocal = process.env.REACT_APP_FIREBASE_USE_LOCAL === "true";
// Initialize firebase instance
if (firebase.apps.length === 0) {
  firebase.initializeApp(config);
  if (firebaseUseLocal) {
    // Point to the RTDB emulator running on localhost.
    firebase.database().useEmulator("localhost", 9000);
    firebase.firestore().useEmulator("localhost", 8080);
    firebase.functions().useEmulator("localhost", 5001);
    firebase.auth().useEmulator("http://localhost:9099");
  }
}
// Initialize other services on firebase instance
firebase.firestore(); // <- needed if using firestore

// initalize firebase analytics
firebase.analytics();

// initialize firebase performance
firebase.performance();

// initialize firebase functions
firebase.functions();

try {
  firebase.messaging();
} catch(e) {
  console.error(e)
}

// Add firebase to reducers
export const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer, // <- needed if using firestore
});

export const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance, // <- needed if using firestore
};
