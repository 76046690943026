import io from "socket.io-client";
import firebase from "firebase/app";
import SimpleSignalClient from "../simple-signal-client";
import { notify } from "../notifications";
import toast from "react-hot-toast";
import ChangeRoomSound from "../../sounds/change-room.mp3";
import UserJoinedSound from "../../sounds/user-joined.mp3";
import { store } from '../../store'

import { eventChannel } from "redux-saga";
import {
  getVideoTrack,
  getAudioTrack,
  stopTrackFromStream,
  removeTrackFromPeers,
  startCapture,
  destroyConnection,
} from "./helpers";
import {
  debounce,
  fork,
  put,
  call,
  cancel,
  take,
  takeLatest,
  cancelled,
  select,
  retry,
} from "redux-saga/effects";

import {
  iceServersSuccess,
  ON_CHANGE_ROOM,
  onChangeRoomSuccess,
  onChangeRoom,
} from "../actions";
import {
  onLobby,
  onPeerClose,
  onPeerData,
  onAddAudioTrack,
  onAddVideoTrack,
  onRemoveAudioTrack,
  onRemoveVideoTrack,
} from "../reducers";

export function* fetchIceServersSaga() {
  const getIceServers = firebase.functions().httpsCallable('getIceServers');
  const response = yield call(getIceServers);
  const iceServers = response.data.ice_servers;
  yield put(iceServersSuccess([iceServers[0], iceServers[1], iceServers[2]]));
}

let signalClient;

window.signalClient = signalClient;

let isLocked = false;

function addStreamToPeer(stream, peer) {
  if (isLocked) return;
  isLocked = true;
  try {
    if (stream.active) {
      console.log("adding existing stream", stream);
      peer.addStream(stream);
    }
  } catch (e) {
    console.log("Could not add stream", stream, "to peer", peer);
    console.error(e);
  }
  isLocked = false;
}
function playSound(notificationSound) {
  const sound = new Audio(notificationSound);
  sound.volume = 0.2;
  try {
    sound.play();
  } catch (error) {
    console.error(error);
  }
}

function createSocketChannel({
  roomId,
  iceServers,
  localStream,
  localScreenShare,
  userId,
  roomName,
}) {
  // `eventChannel` takes a subscriber function
  // the subscriber function takes an `emit` argument to put messages onto the channel
  return eventChannel((emit) => {
    const socket = io.connect("prologe-simple-signal.herokuapp.com/");

    const options = iceServers?.length > 0 ? { config: { iceServers } } : {};
    const payload = { userId };

    signalClient = new SimpleSignalClient(socket);

    signalClient.discover({ roomId });

    async function onPeer(peer, remoteUserId) {
      addStreamToPeer(localStream, peer);
      addStreamToPeer(localScreenShare, peer);

      peer.on("track", (track, remoteStream) => {
        if (track.kind === "video") {
          emit(onAddVideoTrack({ peerId: remoteUserId, track, id: track.id }));
        }
        if (track.kind === "audio") {
          emit(onAddAudioTrack({ peerId: remoteUserId, track, id: track.id }));
        }
        remoteStream.addEventListener("removetrack", (event) => {
          if (event.track.kind === "video") {
            emit(onRemoveVideoTrack({ peerId: remoteUserId, id: track.id }));
          }
          if (event.track.kind === "audio") {
            emit(onRemoveAudioTrack({ peerId: remoteUserId, id: track.id }));
          }
        });

        peer.on("close", () => {
          console.log("closing connection with user", remoteUserId);
          emit(onPeerClose({ peerId: remoteUserId }));
        });
        peer.on("error", (e) => {
          console.log("peer error:");
          console.error(e);
          console.log("attempting to destroy peer");
          peer.destroy();
          console.log("peer destroyed");
          try {
            retry(3, 500, onPeer, [peer, remoteUserId]);
          } catch (e) {
            console.log("tried to connect to peer to many times", e);
          }
        });
      });
    }

    async function connectToPeer(peerID) {
      console.log("connect to peer", peerID);
      try {
        const { peer, metadata } = await signalClient.connect(
          peerID,
          payload,
          options
        ); // connect to the peer
        console.log("connect to peer succeeded");
        const remoteUserId = metadata.userId;

        if (!remoteUserId) {
          console.error("Peer Connect: No user id");
          return;
        }

        emit(onPeerData({ userId: remoteUserId, peerId: remoteUserId, peer }));
        onPeer(peer, remoteUserId);
      } catch (e) {
        console.error("Could not accept peer", e);
      }
    }
    const onRoomPeers = async (discoveryData) => {
      if (!discoveryData.roomData) {
        return;
      }

      if (discoveryData.roomData.id === roomId) {
        discoveryData.roomData.peers.forEach(async (peerID) => {
          connectToPeer(peerID);
        }); // connect to all peers in new room
      }
    };
    const handleRequest = async (request) => {
      try {
        const { peer, metadata } = await request.accept(payload, options);
        const remoteUserId = metadata.userId;
        if (!remoteUserId) {
          console.error("Peer Connect: No user id");
          return;
        }
        emit(onPeerData({ userId: remoteUserId, peerId: remoteUserId, peer }));
        onPeer(peer, remoteUserId);
      } catch (e) {
        console.log("Could not accept peer", e);
      }
    };
    signalClient.on("request", handleRequest);
    signalClient.addListener("discover", onRoomPeers);

    // the subscriber must return an unsubscribe function
    // this will be invoked when the saga calls `channel.close` method
    return () => destroyConnection(signalClient);
  });
}

function* watchRoomPeersSaga(roomId, roomName) {
  const iceServers = yield select((state) => state.webrtc.iceServers);
  const localStream = yield select((state) => state.webrtc.localStream);
  const localScreenShare = yield select(
    (state) => state.webrtc.localScreenShare
  );
  const userId = yield select((state) => state.firebase.auth.uid);

  yield put({ type: "webrtc2/onDiscover" });
  const socketChannel = yield call(createSocketChannel, {
    roomId,
    roomName,
    userId,
    iceServers,
    localStream,
    localScreenShare,
  });

  yield put(onChangeRoomSuccess({ roomId, roomName }));
  while (true) {
    try {
      // An error from socketChannel will cause the saga jump to the catch block
      const payload = yield take(socketChannel);
      yield put(payload);
    } catch (err) {
      console.error("socket error:", err);
      // socketChannel is still open in catch block
      // if we want end the socketChannel, we need close it explicitly
      // socketChannel.close()
    } finally {
      if (yield cancelled()) {
        socketChannel.close();
        console.log("socket close");
      }
    }
  }
}

// added export just to disable compiler warning
export function* onJoinRoomSaga(action) {
  yield onScreenShareOffSaga();
  const { roomId, roomName } = action.payload;

  if (roomName?.match(/open-space|zen-room/)) {
    yield cleanUpSaga();
    yield put(onChangeRoomSuccess({ roomId, roomName }));
    return;
  }


  const task = yield fork(watchRoomPeersSaga, roomId, roomName);

  playSound(ChangeRoomSound);

  yield take("rooms/onChange");
  yield cancel(task);
}

function* onScreenShareOnSaga() {
  const localScreenShare = yield select(
    (state) => state.webrtc.localScreenShare
  );
  try {
    const stream = yield call(startCapture);
    const videoTrack = stream.getVideoTracks()[0];
    videoTrack.onended = () => {
      store.dispatch({ type: 'local/onCaptureOff' })
    }

    localScreenShare.addTrack(videoTrack);
    addTrackToPeers(videoTrack, localScreenShare);
    yield put({ type: "local/onCaptureOnSuccess" });
  } catch (e) {
    yield put({ type: "local/onCaptureOnFailure" });
  }
}
function* onScreenShareOffSaga() {
  const localScreenShare = yield select(
    (state) => state.webrtc.localScreenShare
  );
  const videoTrack = localScreenShare.getTracks()[0];
  try {
    stopTrackFromStream(videoTrack, localScreenShare);
    removeTrackFromPeers(videoTrack, localScreenShare, signalClient);
    yield put({ type: "local/onCaptureOffSuccess" });
  } catch (e) {
    console.error(e);
    yield put({ type: "local/onCaptureOffFailure" });
  }
}

function* onCameraOnSaga() {
  const localStream = yield select((state) => state.webrtc.localStream);
  try {
    const videoTrack = yield call(getVideoTrack);
    localStream.addTrack(videoTrack);
    addTrackToPeers(videoTrack, localStream);
    yield put({ type: "local/onCameraOnSuccess" });
    toast("Camera is currently turned on", {
      icon: "📷",
      duration: 5000,
    });
  } catch (e) {
    toast.error("Error turning on camera, check your browser permissions", {
      icon: "📷",
      duration: 5000,
    });
    yield put({ type: "local/onCameraOnFailure" });
  }
}

function* onCameraOffSaga() {
  const localStream = yield select((state) => state.webrtc.localStream);
  try {
    const videoTrack = localStream.getVideoTracks()[0];
    stopTrackFromStream(videoTrack, localStream);
    removeTrackFromPeers(videoTrack, localStream, signalClient);
    yield put({ type: "local/onCameraOffSuccess" });
  } catch (e) {
    console.error(e);
    yield put({ type: "local/onCameraOffFailure" });
  }
}
function addTrackToPeers(track, stream) {
  if (!signalClient) return;
  if (!signalClient.id) return;
  if (isLocked) return;
  isLocked = true;
  signalClient.peers().forEach((peer) => {
    if (!peer.readable) {
      return;
    }
    console.log("add track to peer", peer);
    peer.addTrack(track, stream);
  });
  isLocked = false;
}

function* onMicOnSaga() {
  const localStream = yield select((state) => state.webrtc.localStream);
  try {
    const audioTrack = yield call(getAudioTrack);
    localStream.addTrack(audioTrack);
    addTrackToPeers(audioTrack, localStream);
    yield put({ type: "local/onMicOnSuccess" });
    toast("Microphone is turned on", { icon: "🎤" });
  } catch (e) {
    toast("Could not turn on mic, check your browser permissions", {
      icon: "🎤",
      duration: 5000,
    });
    console.error(e);
  }
}

function* onMicOffSaga() {
  const localStream = yield select((state) => state.webrtc.localStream);
  try {
    const audioTrack = localStream.getAudioTracks()[0];
    console.log(audioTrack, localStream);
    stopTrackFromStream(audioTrack, localStream);
    removeTrackFromPeers(audioTrack, localStream, signalClient);
    toast("Microphone is turned off", { icon: "🎤" });
    yield put({ type: "local/onMicOffSuccess" });
  } catch (e) {
    console.error(e);
    yield put({ type: "local/onMicOffFailure" });
  }
}

function* cleanUpSaga() {
  yield put({ type: "local/onCaptureOff" });
  yield put({ type: "local/onCameraOff" });
  yield put({ type: "local/onMicOff" });
  yield call(destroyConnection, signalClient);
  yield put({ type: "destroy" });
}

function* chatNotificationSaga(action) {
  const message = action.payload.data.text;
  const uid = action.payload.data.uid;
  const myUserId = yield select((state) => state.firebase.auth.uid);
  if (uid === myUserId) return;
  notify(message);

  yield put({ type: "notification", payload: { message } });
}

function* userNotificationSaga(action) {
  const message = action.payload.data.text;
  notify(message);
  toast(message);

  yield put({ type: "notification", payload: { message } });
}

function* roomEventsSaga(action) {
  const event = action.payload.data;

  const myUserId = yield select((state) => state.firebase.auth.uid);
  if (event.user.id === myUserId) return;

  if (event.type === "rooms/userJoined") {
    const message = `${event.user.displayName} joined`;

    playSound(UserJoinedSound);

    yield put({ type: "notifications", payload: { message } });
    notify(message);
  }

  if (event.type === "rooms/userLeft") {
    const message = `${event.user.displayName} left`;
    yield put({ type: "notifications", payload: { message } });
    notify(message);
  }
}
function* reconnectionSaga(action) {
  const roomName = yield select((state) => state.webrtc.currentRoomName);
  const roomId = yield select((state) => state.webrtc.currentRoomId);
  const isRoomReady = yield select((state) => state.webrtc.isRoomReady);
  if (!isRoomReady) return;
  if (!roomName) return;
  if (!roomId) return;

  if (action.data === true) {
    yield put(onChangeRoom({ roomId, roomName }));
  }
}
function* onLobbySaga() {
  yield cleanUpSaga();
}

export default function* rootSaga() {
  yield takeLatest(onLobby().type, onLobbySaga);

  yield takeLatest("local/onCaptureOn", onScreenShareOnSaga);
  yield takeLatest("local/onCaptureOff", onScreenShareOffSaga);

  yield takeLatest("local/onCameraOn", onCameraOnSaga);
  yield takeLatest("local/onCameraOff", onCameraOffSaga);

  yield takeLatest("local/onMicOn", onMicOnSaga);
  yield takeLatest("local/onMicOff", onMicOffSaga);

  yield debounce(1000, ON_CHANGE_ROOM, onJoinRoomSaga);
  yield takeLatest("@@reactReduxFirebase/LOGOUT", cleanUpSaga);
  yield takeLatest("@@reactReduxFirebase/AUTHENTICATION_INIT_FINISHED", fetchIceServersSaga);

  yield takeLatest(
    (action) =>
      action.type === "@@reduxFirestore/DOCUMENT_ADDED" &&
      action.meta.collection === "roomMessages",

    chatNotificationSaga
  );
  yield takeLatest(
    (action) =>
      action.type === "@@reduxFirestore/DOCUMENT_ADDED" &&
      action.meta.collection === "notifications",
    userNotificationSaga
  );

  yield takeLatest(
    (action) =>
      action.type === "@@reduxFirestore/DOCUMENT_ADDED" &&
      action.meta.collection === "roomNotifications",
    roomEventsSaga
  );

  yield debounce(
    1000,
    (action) =>
      action.type === "@@reactReduxFirebase/SET" &&
      action.path.includes("presence/"),
    reconnectionSaga
  );
}
