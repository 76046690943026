export const getAudioTrack = async () => {
  const constraints = {
    audio: true,
  };
  const stream = await getUserMedia(constraints);
  const audioTrack = stream.getAudioTracks()[0];
  return audioTrack;
};

export const getUserMedia = async (constraints) => {
  return await navigator.mediaDevices.getUserMedia(constraints);
};

export const getVideoTrack = async () => {
  const constraints = {
    video: true,
  };
  const streamWithVideo = await getUserMedia(constraints);
  const videoTrack = streamWithVideo.getVideoTracks()[0];
  return videoTrack;
};

export const stopTrackFromStream = (track, stream) => {
  if (!track) return;
  track.stop();
  stream.removeTrack(track);
};

export function removeTrackFromPeers(track, stream, signalClient) {
  if (!signalClient) return;
  if (!signalClient.id) return;
  if (!track) return;

  signalClient.peers().forEach((peer) => {
    if (!peer.readable) {
      return;
    }
    console.log("removing track from peer", peer);
    peer.removeTrack(track, stream);
  });
}

export async function startCapture() {
  return await navigator.mediaDevices.getDisplayMedia();
}

export const destroyConnection = (signalClient) => {
  if (!signalClient?.id) return;
  console.log("Trying to destroy connection");
  try {
    console.log("Destroying signal client");
    signalClient.peers().forEach((peer) => {
      if (!peer.readable) {
        return;
      }
      peer.destroy();

      console.log("after peer destroy", peer);
    });
    signalClient.socket.close();
  } catch (e) {
    console.log("Could not destroy connection");
    console.error(e);
  }
};
