import { lazy, Suspense } from "react";
import { Provider } from "react-redux";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { Toaster } from "react-hot-toast";
import { rrfProps } from "./firebase.redux";
import { store } from "./store";
// css theme
import "./assets/css/theme.css";
import "./assets/fonts/feather/feather.css";
import Loader from "./components/Loader";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";

const Homepage = lazy(() => import("./pages/Homepage"));
const CreateOffice = lazy(() => import("./pages/CreateOffice"));
const JoinOffice = lazy(() => import("./pages/JoinOffice"));
const Login = lazy(() => import("./pages/Login"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const ResetPassword = lazy(() => import("./pages/ResetPassword"));
const Lobby = lazy(() => import("./pages/Lobby"));

const App = () => {
  return (
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <Toaster position="top-right" toastOptions={{ duration: 3000 }} />
        <Router>
          <Suspense fallback={<Loader />}>
            <Switch>
              <Route path="/signup">
                <JoinOffice />
              </Route>
              <Route path="/onboarding">
                <CreateOffice />
              </Route>
              <Route path="/login">
                <Login />
              </Route>
              <Route path="/forgot-password">
                <ForgotPassword />
              </Route>
              <Route path="/reset-password">
                <ResetPassword />
              </Route>
              <Route path="/lobby/:roomId">
                <Lobby />
              </Route>
              <PrivateRoute path="/">
                <Homepage />
              </PrivateRoute>
            </Switch>
          </Suspense>
        </Router>
      </ReactReduxFirebaseProvider>
    </Provider>
  );
};

export default App;
