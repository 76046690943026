import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { isLoaded, isEmpty } from "react-redux-firebase";
import { Notifications } from "./pages/Notifications";

import Loader from "./components/Loader";

const PrivateRoute = ({ children, ...rest }) => {
  const auth = useSelector((state) => state.firebase.auth);

  const isLoggedIn = isLoaded(auth) && !isEmpty(auth);

  if (!isLoaded(auth)) {
    return <Loader />;
  }
  if (auth.isAnonymous) {
    return <Redirect to="/login" />;
  }

  if (isLoggedIn) {
    return (
      <Route {...rest}>
        {children}
        <Notifications />
      </Route>
    );
  }

  return (
    <Route {...rest}>
      <Redirect to="/login" />
    </Route>
  );
};
export default PrivateRoute;
