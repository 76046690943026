const canUseBrowserNotifications = async () => {
  if (!window.chrome) {
    return false;
  }
  // does the browser support notifications
  if (!("Notification" in window)) {
    return false;
  }

  // are we authorized to use browser notifications
  if (Notification.permission === "granted") {
    return true;
  }

  // if the user hasn't denied notifications ask for permission
  if (Notification.permission !== "denied") {
    const permission = await Notification.requestPermission();
    // if the user does NOT grant the permission return false
    if (permission !== "granted") {
      return false;
    }
  }
  return true;
};

export const notify = async (message) => {
  if (await canUseBrowserNotifications()) {
    new Notification(message);
  }
};
